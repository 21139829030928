.guestDialog {
    padding: 24px;
    max-width: 350px !important;
    width: 100%;
    border-radius: 16px !important;
    position: relative;

    .title {
        button {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }

    .header {
        margin-bottom: 16px;
        text-align: center;
    }

    .guestCount {
        padding: 8px 0 24px;
    }

    .btn {
        margin-bottom: 8px;
    }
}
