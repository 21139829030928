.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;

    .itemIndicator {
        position: relative;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: #88888877;
        overflow: visible !important;
        transition: all 0.1s;

        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: rgba(136, 136, 136, 0.37);
            transform: scale(1.5);
        }

        .loading {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 16px;
            width: 16px;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.statusConnected {
            background-color: rgba(97, 208, 4, 0.62);

            @keyframes kkBlink {
                from {
                    transform: scale(1.2);
                }
                to {
                    transform: scale(1.8);
                }
            }

            &:before {
                background-color: rgba(97, 208, 4, 0.62);
                animation-duration: 1s;
                animation-iteration-count: infinite;
                animation-name: kkBlink;
            }
        }

        &.statusConnecting {
            background-color: rgba(237, 108, 2, 0.38);

            &:before {
                background-color: rgba(237, 108, 2, 0.38);
                transform: scale(1.2);
            }
        }

        &.statusReconnect {
            background-color: rgba(2, 136, 209, 0.36);

            &:before {
                background-color: rgba(2, 136, 209, 0.36);
                transform: scale(1.2);
            }
        }
    }
}
