.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .title {
        font-size: 18px;
        font-weight: 400;
    }

    .action {
        width: 100%;
        max-width: 256px;

        .button {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 56px;
            border-radius: 8px;
            border: 1px solid #7d00d4;
            line-height: 0;

            .display {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 96px;
                color: #7d00d4;
                user-select: none;

                input {
                    text-align: center;
                    border: none;
                    outline: none;
                    background: transparent;
                    font-size: 56px;
                    max-width: 150px;
                    color: #7d00d4;
                }
            }

            button {
                svg {
                    font-size: 72px;
                }
            }
        }
    }

    &.fullWidth {
        width: 100%;

        .action {
            width: 100%;
            max-width: unset;
        }
    }
}
