$animation-time: 0.5s;
$animation-fill-mode: forwards;
$translateX: -132.5%;
$translateY: -55%;
.ellipses {
    animation: reScaleAndPosition $animation-time $animation-fill-mode;
}
.compact {
    animation: scaleAndPosition $animation-time $animation-fill-mode;
}
@keyframes scaleAndPosition {
    0% {
        transform: translateX(0) translateY(0) scale(1);
    }
    100% {
        transform: translateX($translateX) translateY($translateY) scale(2);
    }
}
@keyframes reScaleAndPosition {
    0% {
        transform: translateX($translateX) translateY($translateY) scale(1.5);
    }
    100% {
        transform: translateX(0) translateY(0) scale(1);
    }
}
.text {
    animation: reOpacity $animation-time $animation-fill-mode;
}
.ghost {
    animation: opacity $animation-time $animation-fill-mode;
}
@keyframes opacity {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes reOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
