.customSidebar > div {
    overflow: visible !important;
    transition: 0.3s ease-in-out !important;
}

.MuiPaper-root {
    overflow: visible !important;
}

.sidebarHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    overflow: visible;
    width: 100%;
    top: 30px;
    height: 0;
}

.sidebarShrinkButton {
    background-color: #fff !important;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    color: black !important;
    min-width: 0 !important;
    width: 24px;
    height: 24px;
    // padding: 4px 4px !important;
    padding-top: 4px !important;
    padding-right: 4px !important;
    padding-left: 4px !important;
    padding-bottom: 4px !important;
    top: 35px;
    right: -15px;
    transition: 0.5s;
    z-index: 10000;
    border-radius: 100px !important;
    border: 1px solid var(--light-mode-assistive-dim, #ebecf2) !important;
    background: var(--light-mode-neutral-white, #fff);

    &:hover {
        background-color: #f0f0f0 !important;
    }

    span {
        margin: 0 !important;
    }
}
