.main {
    display: flex;
    align-items: center;
    justify-content: center;

    .status {
        display: inline-flex;
        border-radius: 6px;
        font-size: 13px;
        padding: 3px 8px;
        background-color: #ff27cb;
        color: #fff;
    }
}
