.main {
    display: flex;
    align-items: center;
    justify-content: center;

    .status {
        display: inline-flex;
        border-radius: 6px;
        font-size: 13px;
        padding: 3px 8px;
    }

    &.pending {
        .status {
            background-color: #ff7a00;
            color: #fff;
        }
    }

    &.approved {
        .status {
            background-color: #c7fbe5;
            color: #1c8659;
        }
    }

    &.declined {
        .status {
            background-color: #ff0000;
            color: #fff;
        }
    }
}
