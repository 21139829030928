.container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    gap: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .button {
        box-shadow: none;

        &:not([disabled]) {
            &.approved,
            &.completed {
                background-color: #40845d;
            }

            &.rejected {
                background-color: #df1d17;
            }
        }
    }
}
