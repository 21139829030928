.main {
    display: flex;
    align-items: center;
    justify-content: center;

    .status {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        font-size: 13px;
        padding: 3px 8px;
        background-color: #732cfd;
        color: #fff;
        gap: 4px;
        cursor: pointer;
    }
}
