.loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
}

.receipt {
    display: none;
    padding: 12px;
    max-width: 420px;
    margin: 0 auto;

    &.visible {
        display: block;
    }

    .container {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        color: #1a1c23;
        font-size: 14px;
        font-weight: 400;

        .header {
            width: 100%;
            margin: 0;
            padding: 10px 0 0 0;
            align-items: center;
            justify-content: center;

            .image {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }
        }

        .logo {
            margin: 0;
            padding: 0;
            width: 80px;
            height: 80px;
            object-fit: contain;
            border: 1px #ebecf2 solid;
            border-radius: 16px;
        }

        .title {
            margin: 0;
            font-family: inherit;
            text-align: center;
            font-weight: bold;
            color: #1a1c23;
            font-size: 23px;
            line-height: 36.8px;
        }

        .subTitle {
            margin: 0;
            padding: 0;
            font-family: inherit;
            font-weight: bold;
            color: #616475;
            font-size: 16px;
            line-height: 25.6px;
            text-align: center;
        }

        .orderId {
            margin: 0;
            padding: 0;
            font-family: inherit;
            font-weight: bold;
            color: #1a1c23;
            font-size: 19px;
            line-height: 27.36px;
            padding-top: 16px;
        }
    }

    .footer {
        margin: 16px 24px 30px 24px;
        padding: 16px;
        border-radius: 8px;
        border: solid #6164751f 1px;
        gap: 4px;
        display: flex;

        .content {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title {
                margin: 0;
                font-family: inherit;
                font-weight: bold;
                color: #1a1c23;
                font-size: 16px;
                line-height: 25.6px;
                text-align: center;
                padding-bottom: 4px;
            }

            .description {
                margin: 0;
                font-family: inherit;
                font-weight: 400;
                color: #1a1c23;
                font-size: 12px;
                line-height: 16.68px;
                text-align: center;
            }
        }

        .image {
            margin: 0;
            padding: 0;
            width: 80px;
            height: 80px;
            object-fit: contain;
        }
    }

    .payInfo {
        margin: 0;
        font-family: Arial;
        width: 100%;

        .tip {
            padding: 12px 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 12px 4px;

        .paidWith {
            margin: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 22.4px;
            color: #616475;
        }

        .cardInfo {
            font-weight: 400;
            font-size: 16px;
            line-height: 25.6px;
            color: #1a1c23;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .fullyPaid {
        margin: 0;
        width: 100%;
        font-weight: bold;
        font-size: 16px;
        line-height: 25.6px;
        color: #1a1c23;
        background-color: #22a9581a;
        padding: 12px 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .divider1 {
        background-color: #ebecf2;
        height: 1px;
        width: 100%;
    }

    .divider2 {
        background-color: #ebecf2;
        height: 2px;
        width: 100%;
    }

    .qr {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 24px 0;

        img {
            height: 128px;
            width: 128px;
        }
    }
}
