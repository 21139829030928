/*
    Creation Time: 2022 - April - 7
    Created by:  (hamidrezakk)
    Maintainers:
       1.  HamidrezaKK (hamidrezakks@gmail.com)
    Auditor: HamidrezaKK
    Copyright Qlub_ 2022
*/
@use 'sass:map';
.invoice {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .table {
        color: var(--onSurfaceColors-mediumEmphasis);
    }
    tr {
        td:first-of-type {
            max-width: calc(100vw / 2);

            > span {
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box !important;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: normal;
            }
        }
    }

    .buttons {
        position: relative;
        right: -12px;
        html[dir='rtl'] & {
            right: 12px;
        }
        button {
            margin: 0 3px;
        }
    }

    .textNoWrap {
        white-space: nowrap;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 8px 12px 16px;

        .title {
            width: 100%;
            flex: 1 1;
            text-align: center;
        }
        .refresh {
        }
    }

    .sheduledOrderInfo {
        padding: 16px 0px;
        width: 100%;
    }

    .invoiceTable {
        border-collapse: inherit;
    }

    .orderTable {
        padding: 8px 0;
        width: 100%;
    }

    .iconButton {
        border: solid 1px var(--dim);
        border-radius: 8px;
    }
}
