$printFontSize: 14px;

.container {
    // display: none;
    font-size: $printFontSize;
    padding: 8px;

    > * {
        font-weight: bold;
    }

    .noWrap {
        white-space: nowrap;
    }

    .wordBreak {
        white-space: normal !important;
        word-break: break-all !important;
    }

    .paper {
        .header {
            display: flex;
            align-items: center;
            width: 100%;
            font-weight: bold;
            color: #000000;
            justify-content: space-between;
            margin-bottom: 4px;
        }

        .table {
            tr {
                td {
                    border-bottom: 1px solid #000;
                    font-size: $printFontSize;
                    padding: 4px;
                }

                td.heading {
                    padding-left: 5px;
                    font-weight: bold;
                    background-color: #ffffff;
                    border-bottom: 1px solid #000;
                }

                td.label {
                    font-weight: bold;
                }

                td.value {
                    text-align: center;
                    white-space: nowrap;

                    &.wrap {
                        white-space: normal;
                    }

                    &:last-of-type {
                        text-align: right;
                    }

                    &:nth-last-of-type(1) {
                        text-align: right;
                    }
                }
            }
        }
    }
}

@media print {
    .container {
        display: block;
    }
}
