.main {
    display: flex;
    align-items: center;
    justify-content: center;

    .status {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        font-size: 13px;
        padding: 3px 8px;
        background-color: #1be6ff;
        color: #fff;
        gap: 4px;
        cursor: pointer;

        .icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;

            svg {
                font-size: 13px;
            }
        }
    }
}
