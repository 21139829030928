.main {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 6px;

    .label {
        display: flex;
        align-items: center;
        justify-content: center;

        .status {
            display: inline-flex;
            border-radius: 6px;
            font-size: 13px;
            color: #fff;
            background: #df1d17;
            padding: 3px 8px;
        }
    }
}
