.main {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    gap: 6px;

    &.tableView {
        align-items: center;
        flex-direction: row;
    }

    .status,
    .newStatus {
        display: inline-flex;
        border-radius: 6px;
        font-size: 13px;
        color: #fff;
        padding: 3px 8px;
        border: 1px solid transparent;
    }

    .newStatus {
        background-color: #7d00d4;
        align-items: center;
        gap: 2px;
    }

    &.success {
        .status {
            background-color: #027946;
        }
    }

    &.notPaid {
        .status {
            border-color: #027946;
            background-color: #fff;
            color: #027946;
        }
    }
}
