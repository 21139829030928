@keyframes movingBg {
    from {
        background-position: 0 300px;
    }
    to {
        background-position: 500px 0;
    }
}

@keyframes colorAnimation {
    0% {
        background-image: linear-gradient(
            159.84deg,
            var(--iris-lighter) 2.78%,
            var(--iris-lighter) 31.59%,
            var(--iris) 82.13%
        );
    }
    100% {
        background-image: linear-gradient(
            159.84deg,
            var(--iris-lighter) 2.78%,
            var(--iris-lighter) 31.59%,
            var(--iris) 82.13%
        );
    }
}

@keyframes blackColorAnimation {
    0% {
        background: black;
    }
    100% {
        background: black;
    }
}

.splash {
    position: relative;
    height: 100vh;
    width: 100vw;
    transition: all 1s ease;
    background-image: linear-gradient(
        159.84deg,
        var(--iris-lighter) 2.78%,
        var(--iris-lighter) 31.59%,
        var(--iris) 82.13%
    );
    z-index: 1001;

    &.black {
        background: black;
        animation: blackColorAnimation 1.5s;
    }

    .inner {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-image: url('/images/pattern.svg');
        background-position: center center;
        background-size: 200px auto;
        animation-name: movingBg;
        animation-duration: 20s;
        animation-iteration-count: infinite;

        .container {
            padding: 64px 0 0;

            .logo {
                height: 48px;
            }
        }

        .loading {
            padding: 64px 8px 12px;
            text-align: center;
            color: var(--white);
            font-size: 24px;

            @keyframes kkEllipsis1 {
                0% {
                    transform: scale(0);
                }
                100% {
                    transform: scale(1);
                }
            }
            @keyframes kkEllipsis2 {
                0% {
                    transform: translate(0, 0);
                }
                100% {
                    transform: translate(24px, 0);
                }
            }
            @keyframes kkEllipsis3 {
                0% {
                    transform: scale(1);
                }
                100% {
                    transform: scale(0);
                }
            }

            .kkEllipsis {
                display: inline-block;
                position: relative;
                width: 80px;
                height: 40px;

                div {
                    position: absolute;
                    top: 17px;
                    width: 13px;
                    height: 13px;
                    border-radius: 50%;
                    background: var(--white);
                    animation-timing-function: cubic-bezier(0, 1, 1, 0);

                    &:nth-of-type(1) {
                        left: 8px;
                        animation: kkEllipsis1 0.6s infinite;
                    }

                    &:nth-of-type(2) {
                        left: 8px;
                        animation: kkEllipsis2 0.6s infinite;
                    }

                    &:nth-of-type(3) {
                        left: 32px;
                        animation: kkEllipsis2 0.6s infinite;
                    }

                    &:nth-of-type(4) {
                        left: 56px;
                        animation: kkEllipsis3 0.6s infinite;
                    }
                }
            }
        }
    }
}
