.loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.receipt {
    display: flex !important;
    padding: 12px;
    margin: 0 auto;
    align-items: center;
    flex-direction: column;

    .container {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        color: #1a1c23;
        font-size: 14px;
        font-weight: 400;

        .header {
            margin: 0;
            padding: 10px 12px 0;
            align-items: center;
            justify-content: center;

            .image {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                background-color: #7d00d4;
            }
        }

        .title {
            margin: 0;
            font-family: inherit;
            text-align: center;
            font-weight: bold;
            color: #1a1c23;
            font-size: 23px;
            line-height: 36.8px;
            padding-top: 20px;
        }
    }

    .divider {
        background-color: #ebecf2;
        height: 1px;
        max-width: 420px;
        width: 100%;
    }
    .pText {
        margin: 0;
        padding: 0;
        font-family: inherit;
        color: #616475;
        font-weight: 400;
        font-size: 14px;
        line-height: 20.4px;
        padding-top: 8px;
    }
    .infoContainer {
        display: flex;
        justify-content: space-between;
    }
}
