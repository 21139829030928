.container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: center;

    .icon {
        padding: 0 8px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            font-size: 16px;
        }

        html[dir='rtl'] & {
            padding: 0 0 0 8px;
        }
    }

    .date {
    }
}
