.container {
    position: relative;

    .search {
        padding: 0 0 12px;
        background-color: #fff;
        position: sticky;
        top: -16px;
        z-index: 100;
    }

    &.embed {
        position: relative;
        height: 100%;
        width: 100%;

        .search {
            top: 0;
        }
    }

    .box {
        .item {
            height: 170px;
            border-radius: 8px;
            border: 1px solid #e8e8e8;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 12px;

            .itemHeader {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 24px;
                font-style: normal;
            }

            .itemSubheader {
                flex: 1 1;
                width: 100%;
                padding-top: 8px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                font-size: 16px;
                font-style: normal;
                color: #616475;
            }

            .itemFooter {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                button {
                    background-color: rgba(125, 0, 212, 0.04);
                    border-radius: 24px;
                    padding: 4px 12px;
                    text-transform: none;
                }
            }

            &.selected {
                background-color: #7d00d4;
                color: #fff;

                .itemSubheader {
                    color: #fff;
                }
            }
        }
    }

    &.embed {
        .box {
            .item {
                height: 100px;
                padding: 4px 8px;
                overflow: hidden;

                .itemSubheader {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                    padding-top: 0;
                }
            }
        }
    }

    &.embed {
        .box {
            .item {
                cursor: pointer;
            }
        }
    }
}
