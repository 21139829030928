.buttonContainer {
    position: relative;
    display: inline-block;

    button {
        text-transform: unset;
    }

    .chatIcon {
        position: absolute;
        top: 2px;
        right: 2px;
        font-size: 14px;
        color: #fff;
    }
}
