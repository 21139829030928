.main {
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 6px 0;
    margin: 8px 0 0;

    .row {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding-bottom: 2px;

        .label {
            padding: 2px 6px;
            font-weight: bold;
            flex: 1 1;
            text-align: right;
            font-size: 0.9em;
            color: #9b9eab;

            &.fullWidth {
                flex-basis: 100%;
            }
        }

        .value {
            padding: 2px 6px;
            font-size: 0.9em;
        }

        &.comment {
            .label {
                text-align: left;
            }

            .value {
                padding: 6px;
                margin: 0 8px;
                width: 100%;
                background-color: rgba(252, 232, 0, 0.2);
            }
        }
    }
}
