.layout {
    table-layout: fixed;
    width: 260px !important;
    font-size: 0.8em;

    &[data-size='large'] {
        font-size: 1em;
    }

    tr {
        display: inline-flex;
        width: 100%;

        &[data-mode='divider'] {
            td {
                border-top: 1px solid #000;
                line-height: 0;
            }
        }

        td {
            display: inline-flex;

            &:first-of-type {
                flex: 1 1;
            }

            &[align='right'] {
                text-align: right;
            }
        }

        &[data-indent='2'] {
            td {
                padding-left: 16px;
            }
        }

        &[data-mode='normal'] {
            td {
                font-weight: bold;
            }
        }

        &[data-pb='true'] {
            td {
                padding-bottom: 8px;
            }
        }
    }
}
