.reasonDialog,
.loadingContent {
    padding: 24px;
    max-width: 350px !important;
    width: 100%;
    border-radius: 16px !important;

    .headerStyle {
        margin-bottom: 16px;
        font-weight: bold;
        text-align: center;
    }

    .checkboxList {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .reasonLabel {
            margin: 0;
        }
    }

    .reasonTextInput {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .errorBtn {
        color: #fff;
        background-color: #ff0000;
        margin-bottom: 8px;

        &:hover {
            background-color: #ce0000;
        }
    }
}

.loadingContent {
    max-height: 38vh !important;
    height: 100%;
}
