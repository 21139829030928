.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 8px;
    min-width: 520px;

    @media (screen and min-width: 520px) {
        max-width: 520px;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .title {
            flex: 1 1;
            padding: 0 6px;
        }
    }

    .content {
        position: relative;
        flex: 1 1;

        .page {
            position: absolute;
            top: 8px;
            right: 8px;
            bottom: 8px;
            left: 8px;
            background-color: #fff;
            overflow-y: auto;
            overflow-x: hidden;

            display: flex;
            align-items: center;
            justify-content: center;

            &.overlay {
                z-index: 10000;
            }
        }
    }

    .footer {
        display: flex;
        width: 100%;
        align-items: flex-end;
        justify-content: flex-end;
    }
}
