.container {
    border-radius: 4px;
    padding: 4px 6px;
    color: #fff;
    font-size: 12px;

    &.new {
        background-color: #df1d17;
    }

    &.approved {
        background-color: #ffc21c;
    }

    &.completed {
        background-color: #40845d;
    }

    &.rejected {
        background-color: #863f0c;
    }
}
